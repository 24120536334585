import "./App.css";
import Feature from "./pages/Feature";
import Form from "./pages/Form";
import Homepage from "./pages/Homepage";
import Navbar from "./pages/Navbar";
import Project from "./pages/Project";
import SEO from "./pages/SEO";
import Services from "./pages/Services";

function App() {
  const navopen = () => {
    const a = document.querySelector(".open");
    if (a) {
      a.classList.toggle("open");
    }
  };
  return (
    <div className="App">
      <Navbar />
      <div onClick={navopen} id="homepage">
        <Homepage />
      </div>
      <div onClick={navopen} id="seo">
        <SEO />
      </div>
      <div onClick={navopen} id="services">
        <Services />
      </div>
      <div onClick={navopen} id="feature">
        <Feature />
      </div>
      <div onClick={navopen} id="project">
        <Project />
      </div>
      <div onClick={navopen} id="About-us">
        <Form />
      </div>
    </div>
  );
}

export default App;
