import React, { useEffect, useRef } from "react";
import project1 from "./Assets/IMG-20240202-WA0012.jpg";
import project2 from "./Assets/IMG-20240202-WA0014.jpg";
import project3 from "./Assets/IMG-20240410-WA0052.jpg";
import project4 from "./Assets/IMG-20240712-WA0023.jpg";
import project5 from "./Assets/IMG-20240712-WA0027.jpg";
import project6 from "./Assets/IMG-20240805-WA0030.jpg";
import project7 from "./Assets/IMG20240319165144.jpg";
import project8 from "./Assets/IMG20240803162449.jpg";
import project9 from "./Assets/IMG20240803165208.jpg";
import project10 from "./Assets/IMG20240805172802.jpg";
import project11 from "./Assets/IMG20240805173214.jpg";
import project12 from "./Assets/IMG20240805173324.jpg";

export default function Project() {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let intervalId;

    // Set initial scroll position to 150px
    if (slider) {
      slider.scrollLeft = 175;
    }

    const startAutoSlide = () => {
      intervalId = setInterval(() => {
        if (slider) {
          slider.scrollLeft += slider.clientWidth; // Slide by one full width of the slider
          if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
            slider.scrollLeft = 175; // Reset scroll position at the end
          }
        }
      }, 2000); // Adjust scroll speed
    };

    startAutoSlide();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="projects">
        <div className="projectTitle">
          <h1>SAE gallery</h1>
        </div>
        <div className="projectList" ref={sliderRef}>
          <div className="set">
            <img src={project1} alt="Project 1" />
            <img src={project2} alt="Project 2" />
            <img src={project3} alt="Project 3" />
            <img src={project4} alt="Project 4" />
            {/* <img src={project5} alt="Project 5"/> */}
            <img src={project6} alt="Project 6" />
          </div>
          <div className="set">
            <img src={project7} alt="Project 1" />
            <img src={project8} alt="Project 2" />
            <img src={project9} alt="Project 3" />
            <img src={project10} alt="Project 4" />
            <img src={project11} alt="Project 5" />
            <img src={project12} alt="Project 6" />
          </div>
          <div className="set">
            <img src={project1} alt="Project 1" />
            <img src={project2} alt="Project 2" />
            <img src={project3} alt="Project 3" />
            <img src={project4} alt="Project 4" />
            {/* <img src={project5} alt="Project 5"/> */}
            <img src={project6} alt="Project 6" />
          </div>
          <div className="set">
            <img src={project7} alt="Project 1" />
            <img src={project8} alt="Project 2" />
            <img src={project9} alt="Project 3" />
            <img src={project10} alt="Project 4" />
            <img src={project11} alt="Project 5" />
            <img src={project12} alt="Project 6" />
          </div>
        </div>
      </div>
    </>
  );
}
